<template>
  <div id="cashCollection">
    <v-row>
      <v-col cols="12" class="title-col">
        <div class="title">
          <h1>{{ $t("Cash Collection") }}</h1>
        </div>
      </v-col>
    </v-row>
    <v-form v-model="valid" ref="form" :lazy-validation="lazy">
      <v-row>
        <v-col cols="12" md="6">
          <div class="box">
            <h4 class="second-title">{{ $t("Select Payment") }}</h4>
            <ul class="payment-methods text-center">
              <li class="text-center disabled">
                <!-- <p class="icon" @click="selectPaymentMethod('Visa')"> -->
                <p class="icon">
                  <i class="fas fa-money-check"></i>
                </p>
                <p class="text">{{ $t("Visa") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentMethod == 'Cash' ? 'active' : ''"
                  @click="selectPaymentMethod('Cash')"
                >
                  <i class="far fa-money-bill-alt"></i>
                </p>
                <p class="text">{{ $t("Cash") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentMethod == 'Bank Transfer' ? 'active' : ''"
                  @click="selectPaymentMethod('Bank Transfer')"
                >
                  <i class="fas fa-dollar-sign"></i>
                </p>
                <p class="text">{{ $t("Bank Transfer") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentMethod == 'Check' ? 'active' : ''"
                  @click="selectPaymentMethod('Check')"
                >
                  <i class="far fa-credit-card"></i>
                </p>
                <p class="text">{{ $t("Check") }}</p>
              </li>
            </ul>
          </div>
          <div class="selectInput">
            <label for="select payment" class="main-color">{{
              $t("Cash Point")
            }}</label>
            <v-select
              solo
              :items="paymentObject.cash_points"
              v-model="paymentObject.cash_point_id"
              :disabled="submitted"
              item-text="name"
              item-value="id"
              :rules="[validationRules.required]"
            >
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="box">
            <v-row>
              <v-col cols="12" md="6">
                <p class="key">{{ $t("Transaction Id") }}</p>
                <p class="value">{{ paymentObject.transaction_id }}</p>
              </v-col>
              <v-col cols="10" md="5">
                <p class="key">{{ $t("Transaction Date") }}</p>
                <v-text-field
                  id="transaction_date"
                  autocomplete="off"
                  v-model="paymentObject.transaction_date"
                  solo
                  dense
                  :disabled="submitted"
                  @keydown.prevent
                ></v-text-field>
              </v-col>
              <v-col cols="2" md="1">
                <v-icon
                  medium
                  style="float: right; opacity: 0.5; margin-top: 40px"
                  class="mr-2 main-color dateIcon"
                  >event</v-icon
                >
              </v-col>
              <v-row v-if="paymentMethod == 'Cash'">
                <v-col cols="12" md="6" style="padding-top: 0">
                  <p class="key">{{ $t("Voucher Number") }}</p>
                  <p class="value">{{ paymentObject.voucher_number }}</p>
                </v-col>
              </v-row>
              <v-row v-if="paymentMethod == 'Bank Transfer'">
                <v-col cols="12" md="6" style="padding-top: 0">
                  <p class="key">{{ $t("Transfer Number") }}</p>
                  <v-text-field
                    autocomplete="off"
                    v-model="paymentObject.transfer_number"
                    solo
                    dense
                    :rules="[validationRules.required]"
                    :disabled="submitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="5" style="padding-top: 0">
                  <p class="key">{{ $t("Transfer Date") }}</p>
                  <v-text-field
                    id="transfer_date"
                    autocomplete="off"
                    v-model="paymentObject.transfer_date"
                    solo
                    dense
                    :rules="[checkTransferDate(paymentObject.transfer_date)]"
                    :disabled="submitted"
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="1">
                  <v-icon
                    medium
                    style="float: right; opacity: 0.5; margin-top: 28px"
                    class="mr-2 main-color dateIcon"
                    >event</v-icon
                  >
                </v-col>
              </v-row>
              <v-row v-if="paymentMethod == 'Check'">
                <v-col cols="12" md="6" style="padding-top: 0">
                  <p class="key">{{ $t("Check Number") }}</p>
                  <v-text-field
                    autocomplete="off"
                    v-model="paymentObject.check_number"
                    solo
                    dense
                    :rules="[validationRules.required]"
                    :disabled="submitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="5" style="padding-top: 0">
                  <p class="key">{{ $t("Check Date") }}</p>
                  <v-text-field
                    id="check_date"
                    autocomplete="off"
                    v-model="paymentObject.check_date"
                    solo
                    dense
                    :rules="[validateCheckDate(paymentObject.check_date)]"
                    :disabled="submitted"
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="1">
                  <v-icon
                    medium
                    style="float: right; opacity: 0.5; margin-top: 28px"
                    class="mr-2 main-color dateIcon"
                    >event</v-icon
                  >
                </v-col>
              </v-row>
            </v-row>
          </div>
          <div class="textAreaInput">
            <label for="textarea">{{ $t("Notes") }}</label>
            <v-textarea
              rows="2"
              v-model="paymentObject.notes"
              row-height="5"
              :disabled="submitted"
              solo
            ></v-textarea>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <label>{{ $t("Attach Receipt Code") }}</label>
          <v-autocomplete
            :items="receipts"
            item-text="id"
            item-value="id"
            solo
            v-model="paymentObject.receipt_id"
            :disabled="submitted"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="2">
          <h3 class="second-title">{{ $t("Payment Details") }}</h3>
        </v-col>
        <v-col cols="12" md="10" class="info-list">
          <v-row>
            <v-col cols="6" md="3"
              ><p class="value">
                <strong>{{ $t("Name") }}:</strong>
                {{ applicant_details.applicant_name }}
              </p></v-col
            >
            <v-col cols="6" md="3"
              ><p class="value">
                <strong>{{ $t("Partner Group") }}:</strong>
                {{ applicant_details.partner_group }}
              </p></v-col
            >
            <v-col cols="6" md="3"
              ><p class="value">
                <strong>{{ $t("ID") }}:</strong>
                {{ applicant_details.applicant_code }}
              </p></v-col
            >
            <v-col cols="6" md="3"
              ><p class="value">
                <strong>{{ $t("Invoice Number") }}:</strong>
                {{ invoice.invoice_number }}
              </p></v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <hr class="basic" />

      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :options.sync="options"
            :headers="headers"
            :items="items"
            :items-per-page="5"
            class="elevation-1 level1"
            loading-text="Loading... Please wait"
            hide-default-footer
          >
            <template v-slot:item.counter="{ item }">
              <span>{{ items.indexOf(item) + 1 }}</span>
            </template>

            <template v-slot:item.due_date="{ item }">
              <v-icon
                v-if="item.type != 'tax'"
                color="#ff8972"
                class="calendar-icon"
                >calendar_today</v-icon
              >
              <span class="due-date">{{ item.due_date }}</span>
            </template>
            <template v-slot:item.discount="{ item }">
              <v-text-field
                v-if="item.type != 'tax'"
                :disabled="item.has_discount"
                v-model="item.discount"
                solo
                style="margin-top: 10px !important"
                :rules="[
                  CheckDiscountAmount(
                    item.discount,
                    item.due_amount,
                    item.has_discount
                  ),
                  validationRules.numericNotZero,
                ]"
                @keyup="updatePaymentData(true)"
                @change="updatePaymentData(true)"
              ></v-text-field>
            </template>

            <template v-slot:item.net_amount="{ item }">
              <p v-if="item.type != 'tax' && !item.taxable">
                {{ item.net_amount }}
              </p>
              <p v-if="item.type != 'tax' && item.taxable">
                {{ item.net_amount }}
              </p>
            </template>

            <template v-slot:item.amount_paid="{ item }">
              <!-- v-if="item.type != 'tax'" -->
              <v-text-field
                v-model="item.amount_paid"
                solo
                style="margin-top: 10px !important"
                :rules="[
                  CheckAmountPaid(
                    item.amount_paid,
                    item.remaining_amount,
                    item.discount,
                    item.net_amount,
                    item.has_discount
                  ),
                  validationRules.floatNumber,
                  validationRules.required,
                ]"
                @keyup="updatePaymentData"
                @change="updatePaymentData"
              ></v-text-field>
              <!-- <v-text-field
                v-else
                v-model="item.amount_paid"
                solo
                style="margin-top: 10px !important"
                disabled
              ></v-text-field> -->
            </template>
            <template v-slot:item.remaining_amount="{ item }">
              <!-- {{ parseInt(item.amount) - parseInt(item.amount_paid) }} -->
              {{ remainingAmount(item) }}
            </template>
            <template v-slot:item.amount="{ item }">
              <p v-if="item.type != 'tax' && !item.taxable">
                {{ item.amount }}
              </p>
              <p v-if="item.type != 'tax' && item.taxable">
                {{ item.amount }}
              </p>
              <p v-if="item.type == 'tax'">{{ item.amount }}</p>
            </template>

            <template v-slot:item.due_amount="{ item }">
              <span v-if="item.type == 'tax'">{{ item.due_amount }}%</span>
              <span v-if="item.type != 'tax'">{{ item.due_amount }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
    <hr class="second" />

    <v-row>
      <v-col cols="6" md="2" sm="6">
        <p class="stats">
          <strong>{{ $t("Total Before Tax") }}: </strong>
          {{ totalAmountBeforTax }}
        </p>
      </v-col>
      <v-col cols="6" md="2" sm="6">
        <p class="stats">
          <strong>{{ $t("Tax Amount") }}: </strong> {{ taxAmount }}
        </p>
      </v-col>
      <v-col cols="6" md="2" sm="6">
        <p class="stats">
          <strong>{{ $t("Total") }}: </strong> {{ total }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total After Discount") }}: </strong>
          {{ totalAfterDiscount }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total amount paid") }}: </strong> {{ totalAmountPaid }}
        </p>
      </v-col>
      <v-col cols="12" v-if="validationMsg">
        <p class="error--text text-center mb-0 font-bold">
          {{ validationMsg }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="text-center">
        <v-btn
          @click="savePayment"
          color="#7297FF"
          :disabled="loading"
          :loading="loading"
          v-if="!submitted"
          >{{ $t("Save Payment") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "CashCollection",
  data() {
    return {
      validationMsg: "",
      loading: false,
      receipts: [],
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "counter",
        },
        { text: this.$i18n.t("Fee Type"), value: "item", sortable: false },
        {
          text: this.$i18n.t("Due Amount"),
          value: "due_amount",
          sortable: false,
        },
        { text: this.$i18n.t("Due Date"), value: "due_date", sortable: false },
        { text: this.$i18n.t("Discount"), value: "discount", sortable: false },
        {
          text: this.$i18n.t("Amount Paid"),
          value: "total_amount_paid",
          sortable: false,
        },
        {
          text: this.$i18n.t("Amount to pay"),
          value: "amount_paid",
          sortable: false,
        },
        {
          text: this.$i18n.t("Net After Discount"),
          value: "amount",
          sortable: false,
        },
        {
          text: this.$i18n.t("Tax Amount"),
          value: "tax_amount",
          sortable: false,
        },
        {
          text: this.$i18n.t("Net After Tax"),
          value: "net_amount",
          sortable: false,
        },
        {
          text: this.$i18n.t("Remaining Amount"),
          value: "remaining_amount",
          sortable: false,
        },
        // { text: this.$i18n.t("Notes"), value: "notes", sortable: false },
      ],
      items: [],
      paymentObject: {},
      options: {
        itemsPerPage: 100,
      },
      invoice_id: "",
      applicant_details: {},
      invoice: {},
      selected_Items: [],
      invoiceData: {},
      submitted: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      paymentMethod: "Cash",
      // validationRules: {
      //   transfer_number: [v => !!v || "Transfer number is required"],
      //   transfer_date: [v => !!v || "Transfer date is required"],
      //   check_number: [v => !!v || "Check number is required"],
      //   check_date: [v => !!v || "Check date is required"],
      //   cash_point: [v => !!v || "Cash point is required"]
      // },
      valid: false,
      lazy: false,
      // totalAfterDiscount: 0
    };
  },
  mixins: [validationMixin],
  computed: {
    totalAmountBeforTax: {
      get() {
        let num = 0;
        this.items.forEach((item) => {
          if (item.type == "item") {
            num += Number(item.due_amount);
          }
        });

        return num;
      },
    },
    totalAmountForTaxable: {
      get() {
        let num = 0;
        this.items.forEach((item) => {
          if (
            item.type == "item" &&
            (item.taxable == 1 || item.taxable == true)
          ) {
            num += Number(item.amount);
            //
          }
        });

        return num;
      },
    },
    taxPercentage: {
      get() {
        let tax = 0;
        this.items.forEach((item) => {
          if (item.type == "tax") {
            tax += Number(item.due_amount);
          }
        });

        return tax;
      },
    },
    taxAmount: {
      get() {
        return (this.taxPercentage * this.totalAmountForTaxable) / 100;
      },
    },
    total: {
      get() {
        return (
          this.totalAmountBeforTax +
          (this.taxPercentage * this.totalAmountForTaxable) / 100
        );
      },
    },
    totalAfterDiscount: {
      get() {
        let totalDisount = this.total;
        this.items.forEach((item) => {
          if (item.discount != "" && item.discount != undefined) {
            totalDisount -= item.discount;
          }
        });
        return totalDisount;
      },
      // setter
      set: function (newValue) {
        this.totalAfterDiscount = newValue;
      },
    },
    totalAmountPaid: {
      get() {
        let totalPaid = 0;
        this.items.forEach((item) => {
          // console.log(item);
          if (item.amount_paid != "" && item.amount_paid != undefined) {
            totalPaid += Number(item.amount_paid);
          }
        });
        return totalPaid;
      },
      // setter
      set: function (newValue) {
        this.totalAmountPaid = newValue;
      },
    },
    remainingAmount() {
      return (item) => {
        if (parseInt(item.total_amount_paid) == 0) {
          if (item.discount != null && item.discount != "") {
            if (
              parseInt(item.due_amount) ==
              parseInt(item.amount_paid) + parseInt(item.discount)
            ) {
              return 0;
            }
          }
          return parseInt(item.amount) - parseInt(item.amount_paid);
        } else {
          console.log("remaining", parseInt(item.total_amount_paid));
          // if (item.discount != null && item.discount != "") {
          //   return (
          //     parseInt(item.due_amount) -
          //     (parseInt(item.discount) +
          //       parseInt(item.total_amount_paid) +
          //       parseInt(item.amount_paid))
          //   );
          // }
          return (
            parseInt(item.amount) -
            (parseInt(item.amount_paid) + parseInt(item.total_amount_paid))
          );
          // return (
          //   parseInt(item.amount) -
          //   (parseInt(item.amount_paid) + parseInt(item.total_amount_paid))
          // );
        }
      };
    },
    // setter
  },
  watch: {
    items: {
      handler() {
        this.updatePaymentData();
      },
      deep: true,
    },
  },
  methods: {
    // CalculatTotalAmountPaid() {
    //   this.items.forEach(item => {
    //     if (item.type != "tax" && !item.taxable) {
    //       item.amount_paid = item.amount - item.discount;
    //     }
    //     if (item.type != "tax" && item.taxable) {
    //       item.amount_paid =
    //         item.amount +
    //         (item.amount * this.items[this.items.length - 1]["due_amount"]) /
    //           100 -
    //         item.discount;
    //     }
    //   });
    // },
    CheckAmountPaid(
      amount_paid,
      remaining,
      discount,
      net_amount,
      has_discount
    ) {
      // if (parseInt(amount_paid) == 0) {
      //   return "Please put accepted amount";
      // }
      if (discount != "" && discount != null) {
        let m;
        if (has_discount == 1) {
          m = remaining;
        } else {
          m = remaining - discount;
        }

        if (amount_paid > m) {
          return "Please put accepted amount";
        }
      }
      if (
        !has_discount &&
        (amount_paid > remaining || amount_paid > net_amount)
      ) {
        return "Please put accepted amount";
      } else return true;
    },
    // GetTotalAfterDiscount() {
    //   this.totalAfterDiscount = this.total;
    //   this.items.forEach(item => {
    //     if (item.discount != "" && item.discount != null) {
    //       this.totalAfterDiscount -= item.discount;
    //     }
    //   });
    // },
    CheckDiscountAmount(discount, due_amount, has_discount) {
      // this.CheckAmountPaid();
      // console.log(has_discount);
      // console.log(discount);
      // console.log(due_amount);
      // console.log(remaining);
      if (has_discount == 0) {
        if (discount > due_amount) {
          return "Please put accepted discount";
        } else return true;
      } else return true;
    },
    checkTransferDate(value) {
      if (this.paymentMethod == "Bank Transfer") {
        return value ? true : "Transfer number is required";
      } else {
        return true;
      }
    },
    validateCheckDate(value) {
      if (this.paymentMethod == "Check") {
        return value != "" ? true : "Check Date is required";
      } else {
        return true;
      }
    },
    selectPaymentMethod(method) {
      if (!this.submitted) {
        this.$refs.form.resetValidation();
        this.paymentMethod = method;
        var _this = this;
        if (this.paymentMethod == "Bank Transfer") {
          // this.paymentObject.voucher_number = null;
          this.paymentObject.check_number = null;
          this.paymentObject.check_date = null;
          $(document).ready(function () {
            $("#transfer_date").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.paymentObject.transfer_date = dateH;
                } else {
                  _this.paymentObject.transfer_date = "";
                }
              },
            });
          });
          axios
            .get(this.getApiUrl + "/payments/getCashPoints/Transfer", {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              this.paymentObject.cash_points = response.data.data;
            });
        } else if (this.paymentMethod == "Check") {
          // this.paymentObject.voucher_number = null;
          this.paymentObject.transfer_number = null;
          this.paymentObject.transfer_date = null;
          $(document).ready(function () {
            $("#check_date").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.paymentObject.check_date = dateH;
                } else {
                  _this.paymentObject.check_date = "";
                }
              },
            });
          });
          axios
            .get(
              this.getApiUrl + "/payments/getCashPoints/Check",

              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.paymentObject.cash_points = response.data.data;
            });
        } else if (this.paymentMethod == "Cash") {
          this.paymentObject.check_number = null;
          this.paymentObject.check_date = null;
          this.paymentObject.transfer_number = null;
          this.paymentObject.transfer_date = null;
          axios
            .get(
              this.getApiUrl + "/payments/getCashPoints/Cash",

              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.paymentObject.cash_points = response.data.data;
            });
        }
      }
    },
    getPaymentInvoiceItems() {
      axios
        .get(this.getApiUrl + "/invoices/getInvoiceData/" + this.invoice_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.items = response.data.data.items;
          this.invoiceData = response.data.data;
          this.applicant_details = response.data.data.applicant_datails;
          this.invoice = response.data.data.invoice;

          this.items.forEach((item) => {
            if (this.selected_Items.includes(item.item_id) == false) {
              if (item.type == "tax") {
                this.taxPercentage = item.due_amount;
              }
            }
          });
          // this.updatePaymentData();
        });
    },

    getPaymentData() {
      axios
        .get(this.getApiUrl + "/payments/getPaymentData/" + this.invoice_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.paymentObject = response.data.data.payment;
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        });
    },

    savePayment() {
      this.validationMsg = "";
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        if (this.totalAmountPaid > 0) {
          // this.loading = true;
          axios
            .post(
              this.getApiUrl + "/invoices/addDiscount/" + this.invoice_id,
              {
                invoice_items: this.items,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == false) {
                if (this.paymentMethod != "Cash") {
                  this.paymentObject.voucher_number = null;
                }
                axios
                  .post(
                    this.getApiUrl + "/payments/store/" + this.invoice_id,
                    {
                      payment: this.paymentObject,
                      type: this.paymentMethod,
                      items: this.items,
                      total: this.totalAfterDiscount,
                      totalPaid: this.totalAmountPaid,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + localStorage.token,
                      },
                    }
                  )
                  .then((response) => {
                    this.loading = false;
                    if (response.data.status.error == false) {
                      this.submitted = true;
                      this.snack = true;
                      this.snackColor = "success";
                      this.snackText =
                        "payment has been processed successfully";
                    } else {
                      this.snack = true;
                      this.snackColor = "error";
                      this.snackText = response.data.status.message;
                    }
                  });
              } else {
                this.loading = false;
                this.snack = true;
                this.snackColor = "error";
                this.snackText = "An error has occurred";
              }
            });
        } else {
          this.validationMsg = this.$i18n.t("Total amount paid must be > 0");
        }
      }
    },
    checkPaymentOnInvoice() {
      axios
        .get(
          this.getApiUrl + "/payments/checkPaymentOnInvoice/" + this.invoice_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.data.hasPayment) {
            this.$router.push({
              path: "/invoices",
            });
          }
        });
    },
    getReceipts() {
      axios
        .get(this.getApiUrl + "/payments/all_receipts", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.receipts = response.data.data;
          }
        });
    },

    // Update Payment Data
    updatePaymentData(discountCall = false) {
      //
      console.log("called update payment data");
      // this.taxPercentage;
      var totalTaxPaidAmount = 0;
      this.items.forEach((item) => {
        if (item.type == "item") {
          var taxAmount = 0;
          if (item.discount && item.discount != "") {
            item.amount = parseInt(item.due_amount) - parseInt(item.discount);

            // if (discountCall)
            //   item.amount_paid = item.amount - item.total_amount_paid;

            taxAmount = parseInt((item.amount * this.taxPercentage) / 100);
            item.tax_amount = taxAmount;
            item.net_amount = parseInt(item.amount) + parseInt(taxAmount);
            // if (this.invoice.paid == "not paid") {
            // }
          } else if (item.discount == null || item.discount == "") {
            item.amount = parseInt(item.due_amount);

            // if (discountCall)
            //   item.amount_paid = item.amount - item.total_amount_paid;
            // if (item.net_amount == item.amount)
            //   item.amount_paid =
            //     parseInt(item.remaining_amount) - parseInt(item.discount);
            // else item.amount_paid = parseInt(item.remaining_amount);
            taxAmount = parseInt((item.amount * this.taxPercentage) / 100);
            item.tax_amount = taxAmount;
            item.net_amount = parseInt(item.amount) + parseInt(taxAmount);
            // if (this.invoice.paid == "not paid") {
            // }
          }
          totalTaxPaidAmount += taxAmount;
          if (item.taxable == 1 || item.taxable == true) {
            //
          }
        } else {
          if (this.invoice.paid == "not paid") {
            item.amount = totalTaxPaidAmount;
          }
          // if (discountCall)
          //   item.amount_paid = item.amount - item.total_amount_paid;
        }
        if (item.total_amount_paid) {
          if (item.has_discount) {
            if (
              parseInt(item.discount) +
                parseInt(item.total_amount_paid) +
                parseInt(item.amount_paid) >
              parseInt(item.due_amount)
            ) {
              item.amount_paid = item.amount - item.total_amount_paid;
            }
          } else {
            if (
              parseInt(item.total_amount_paid) + parseInt(item.amount_paid) >
              parseInt(item.amount)
            ) {
              item.amount_paid = item.amount - item.total_amount_paid;
            }
          }
        } else {
          if (item.has_discount) {
            if (
              parseInt(item.discount) + parseInt(item.amount_paid) >
              parseInt(item.due_amount)
            ) {
              item.amount_paid = item.amount;
            }
          } else {
            if (parseInt(item.amount_paid) > parseInt(item.amount)) {
              item.amount_paid = item.amount;
            }
          }
        }

        // todo percentage discount
      });
    },
  },
  mounted() {
    this.getReceipts();
    var _this = this;
    $(document).ready(function () {
      $("#transaction_date").calendarsPicker({
        dateFormat: "dd/mm/yyyy",
        onSelect: function (date) {
          if (date[0]) {
            var dateH =
              date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
            _this.paymentObject.transaction_date = dateH;
          } else {
            _this.paymentObject.transaction_date = "";
          }
        },
      });
    });

    this.invoice_id = this.$router.currentRoute.params.invoiceId;
    this.checkPaymentOnInvoice();
    this.getPaymentInvoiceItems();
    this.getPaymentData();
    // this.updatePaymentData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.info-list {
  .value {
    font-size: 13px;
  }
}
.paymentBtn {
  padding-top: 6px !important;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}
.v-data-table th {
  height: 25px;
}

.box {
  box-shadow: $box-shadow;
  width: 90%;
  padding: 10px 10px;
  margin-bottom: 1rem;
  height: 222px;
  .key {
    margin-bottom: 8px;
  }
  .value {
    color: $main-gray;
    margin-bottom: 8px;
  }
}

.payment-methods {
  padding-left: 0;
  li {
    display: inline-block;
    width: 25%;

    .icon {
      font-size: 2rem;
      color: $main-color;
      margin-bottom: 8px;
      padding: 5px 20px 5px;
      margin: 0 5px;
      border-radius: 5px;
    }
    .text {
      color: $main-color;
    }
  }
}

.active {
  background-color: $main-backgroundColor;
}

.selectable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;

  .icon {
    color: $main-gray !important;
  }

  .text {
    color: $main-gray !important;
  }
}

.selectInput {
  width: 90%;
  // padding-left: 12px;
  .v-input {
    max-width: 70%;
  }
}

.textAreaInput {
  // padding-left: 12px;
  width: 90%;
  label {
    color: $main-color;
  }
}

.calendar-icon {
  padding-right: 5px;
}
.due-date {
  color: #ff8972;
}

.second-title {
  color: $main-color;
}

@media (max-width: 600px) {
  .second-title {
    margin-bottom: 0;
  }
  .box {
    height: auto;
    width: 100%;
    .payment-methods {
      li {
        .icon {
          padding: 5px 10px 5px;
        }
      }
    }
  }

  .info-list .value {
    margin-bottom: 0;
  }

  .selectInput {
    width: 100%;
    .v-input {
      max-width: 100%;
    }
  }

  .textAreaInput {
    width: 100%;
  }
}

.error--text {
  font-size: 1.1rem;
}
</style>
